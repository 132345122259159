hr {
  width: 100%;
  margin: 0;
  color: grey;
  border: 1px splid gray;
  opacity: 1;
  margin: 0.5rem;
}

.timeRenderer-containerColumn {
  transition: linear 1000ms;
  position: relative;
  display: flex;

  flex-wrap: wrap;

  align-items: center;
  justify-content: space-around;
  width: 100%;
  /* height: 55%; */
  border-radius: 8px;
  border: 1px solid grey;
  padding: 0 0.125rem 0 0.4rem;
}

.column-around {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 46% !important;
  height: 100%;
}

.timeRenderer-containerColumn-scoreCard {
  transition: linear 1000ms;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #424242;
}

.font-scoreCard {
  font-size: min((1rem + 20vw), (35vh - 50px)) !important;
  line-height: 1;
  font-family: Verdana, sans-serif;
  font-weight: 700;
}

.timeRenderer-scoreWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
}

.timeRenderer-timerText {
  padding: 0;
  margin: 0;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  font-weight: bold;
  color: #fff;
  font-size: calc(4rem + 18vh);
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeRenderer-adjustButton {
  border-radius: 6px;
  border: 2px solid #424242;
  margin: 1.2em;
  padding: 1em 1.2em;
  font-size: 1.5em;
  background-color: transparent;
  color: #424242;
  font-weight: 500;
  &:hover {
    background-color: #424242;
    color: #fff;
    cursor: pointer;
  }
}

.timeRenderer-adjustButtonInner {
  width: auto;
  border-radius: 6px;
  border: 2px solid #424242;
  margin: 0.4rem 0.8rem;
  padding: 1rem 1.2rem;
  font-size: 1.5em;
  background-color: #fff;
  color: #424242;
  font-weight: 500;
  &:hover {
    background-color: #424242;
    color: #fff;
    cursor: pointer;
  }
}

.timeRenderer-buttonsContainer {
  /* max-width: 1280px; */
  width: 100%;
  height: 45%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.timeRenderer-row {
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeRenderer-cell {
  display: flex;
  flex-direction: row;
  padding: 0.1em;
  align-items: center;
  justify-content: space-between;
  border: transparent;
  border-radius: 8px;
  height: 90%;
}

.timeRenderer-rowReverse {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  padding: 0.2rem;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 8px;
}

.timeRenderer-rowCell {
  display: flex;
  padding: 1em;
  align-items: center;
  justify-content: space-between;
  border: transparent;
  border-radius: 8px;
  position: absolute;
  top: 0;
  color: #424242;
  width: 100vw;
}

.timeRenderer-column {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.2em;
  border: transparent;
  border-radius: 8px;
  background-color: transparent;
  color: #424242;
  padding: 0.1rem 0.4em;
}

.timeRenderer-playerInfo {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #424242;
  border-radius: 8px;
  /* margin: 0.2rem; */
  padding: 0.7rem;
  height: 95%;
  width: 48%;
  background: white;
}

.timeRenderer-colorButton {
  padding: 0.2rem 0.6rem;
  font-size: 1em;
  margin: 0.5em;
  color: #424242;
  background-color: transparent;
  &:hover {
    background-color: #424242;
    color: #fff;
  }
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */

.TimeOutTimer-Column {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.2em;
  border: transparent;
  border-radius: 8px;
  background-color: transparent;
  color: #424242;
}

.TimeOutTimer-Row {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.125rem 0 0.4rem;
  border: 1px solid gray;
  border-radius: 8px;
  color: black;
  flex-wrap: wrap;
  line-height: 1;
}

.bg-red {
  background-color: #ff0000;
  color: #fff;
}

/*  */
/*  */
/*  */
/*  */

.PoolTimerInitializer-ContainerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 60px);
  margin: 0 auto;
  /* padding: 1rem; */
}

.PoolTimerInitializer-Column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1ems;
}

.PoolTimerInitializer-Spacing {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.6rem 0.2rem;
}

.PoolTimerInitializer-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PoolTimerInitializer-SpacingRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.3em;
}

.PoolTimerInitializer-ColorButton {
  padding: 0.3rem 1rem;
  border: 1px solid #424242;
  color: #424242;
  background-color: transparent;
  &:hover {
    background-color: #424242;
    color: #fff;
  }
}

.PoolTimerInitializer-Label {
  font-size: 1.125em;
  font-weight: 300;
  margin: 0.4em 0.8em;
}

.PoolTimerInitializer-Input {
  font-size: 1.125rem;
  font-weight: 300;
  margin: 0.4em 0.8em;
  padding: 0.2em 0.4em;
  width: 4rem;
  border-radius: 6px;
  outline: none;
  text-align: right;
  border: 1px solid #424242;
}

.PoolTimerInitializer-AdjustButton {
  border-radius: 6px;
  border: 1px solid #424242;
  padding: 0.4em;
  margin: 0 0.4em;
  font-size: 0.9rem;
  &:hover {
    background-color: #424242;
    color: #fff;
    cursor: pointer;
  }
}

.PoolTimerInitializer-Wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  /* height: 100%; */
  margin: 0 auto;
  padding: 1rem;
}

.PoolTimerInitializer-OuterContainer {
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
  border-radius: 8px;
  border: 1px solid #9e9e9e;
  margin: 0 0.6rem;
  background-color: #fff;
}

.PoolTimerInitializer-ResponsiveContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 1rem;
  background-color: #fff;
}

.PoolTimerInitializer-ResponsiveContainerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90vh;
  margin: 1rem auto;
  padding: 2rem 1rem;
  background-color: #fff;
}

.custom-switch input {
  background-color: rgb(175, 175, 175);
  width: 40px;
  height: 25px;
  outline: none !important;
  border: none;
}
.custom-switch input:not(:checked) {
  background-color: rgb(175, 175, 175);
  width: 40px;
  height: 25px;
  outline: none !important;
  border: none;
}

.custom-switch input:checked {
  background-color: rgb(0, 213, 0);
  width: 40px;
  height: 25px;
  outline: none !important;
  border: none;
}

.shadow {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2) !important;
}

.wrapper-disabled {
  /* pointer-events: none; */
  opacity: 0.5;
}

.btn {
  /* --bs-btn-padding-x: 0.5rem;
  --bs-btn-padding-y: 0.25rem;
  font-weight: bold; */
  margin: 0.15rem;
}

.timeout-text {
  font-weight: bold;
  color: #fff;
  height: 100%;
  margin: 0;
  padding: 0 5px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter-text {
  font-weight: bold;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 2;
  font-size: 1.5rem;
  color: black;
}

.board-row-title {
  font-size: 1rem;
  font-weight: bold;
}

hr {
  width: 95%;
}

@media screen and (max-width: 800px) {
  .board-row-title {
    font-size: 0.65rem;
  }

  .timeRenderer-containerColumn {
    justify-content: center !important;
    padding: 0.1rem;
    margin: 0.2rem;
    width: 96%;
  }

  .timeRenderer-playerInfo {
    width: 100%;
    height: 96%;
    margin: 0.2rem;
    padding: 0.2rem;
  }

  .timeRenderer-containerColumn-scoreCard {
    width: 90%;
    height: 92%;
    margin: 0.1rem;
    padding: 0.1rem;
    border-radius: 4px;
  }

  .TimeOutTimer-Row {
    justify-content: center;
  }

  .timeout-text {
    height: auto;
    margin-top: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
  }

  .buttonIn {
    font-size: 0.7rem;
    padding: 0 !important;
    margin: 0 !important;
  }

  .timeRenderer-rowCell {
    font-size: 0.8rem;
  }
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.score-counter-text {
  font-weight: bold;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 0;

  color: black;
}

.p5 {
  padding: 5px !important;
}
