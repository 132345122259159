.App {
  text-align: center;
  font-size: calc(8px + 1vmin);
}

@font-face {
  font-family: "Boge";
  src: local("Boge"), local("Boge"), url("../fonts/Boge.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.BogeSpan {
  font-family: "Boge";
  letter-spacing: 10px;
}

.card {
  width: 100%;
  max-width: 500px;
  max-height: 300px;
  margin: 0 auto;
  border-radius: 10px;
  /* background-color: #282c34; */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  padding: 0.2em;
  box-sizing: border-box;
  margin-bottom: 20px;
  overflow: hidden;
}

.card:hover {
  scale: 1.03;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), 0 3px 6px rgba(0, 0, 0, 0.5);
  transition: ease-in-out 0.2s;
}

.card-img-top {
  width: 100%;
  max-height: 300px;
  border-radius: 10px;
  object-fit: cover;
  overflow: hidden;
  aspect-ratio: 16/9;
}

.card-body {
  overflow: hidden;
}

.bg-info-new {
  background-color: #0083ac !important;
  color: black !important;
}

.text-grey {
  color: #595959 !important;
}

.navLink:hover {
  color: #00c3ff !important;
  background-color: #3e3e3e !important;
}

.row-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}
